@mixin for-phone-only {
  @media (max-width: $phone-only) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $tablet-portrait-up) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $tablet-landscape-up) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: $desktop-up) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: $big-desktop-up) { @content; }
}
