button {
  font-family: 'TeleNeo', sans-serif;
  &:focus {
    outline: none;
  }
}

.rounded-button {
  padding: 0;
  border: none;
  background-color: $button-background;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 200ms;
  max-height: 40px;

  &:not(.static-btn) {
    background-color: $button-background-selected;
    &:hover {
      background-color: $button-bg-hover;
    }
  }

  &.static-btn {
    cursor: default;
    .button-content {
      .button-icon {
        background-color: $button-icon-bg !important;
      }
    }
  }

  &.selected-btn {
    background-color: $button-background;
    .button-content {
      .button-icon {
        background-color: $button-icon-bg;
      }
    }
  }

  .button-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 150px;

    .button-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      background-color: $button-icon-bg-selected;
      transition: background-color 200ms;

      svg {
        fill: $white;
        height: 22px;
        width: 22px;
        border-radius: 50%;
      }
    }

    .button-text {
      padding: 0 25px 0 15px;
      color: $white;
      font-size: 1rem;
    }
  }
}

.detail-button {
  padding: 0;
  border: none;
  background-color: $detail-button-bg;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 200ms;

  &:not(.static-btn):hover {
    background-color: $detail-button-bg-hover;
  }

  .button-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .button-text {
      padding: 0 25px 0 25px;
      color: $white;
      font-size: 20px;
    }

    .button-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 60px;
      border-radius: 20px;
      background-color: $magenta;

      svg {
        fill: $white;
        height: 60px;
        width: 60px;
        border-radius: 20px;
      }
    }
  }
}

.save-button {
  padding-top: 20px;

  button {
    border-radius: 20px;
    font-size: 20px;
    padding: 10px 30px;
    border-color: transparent;
    background-color: $white;
    cursor: pointer;

    &:hover {
      background-color: #fbfbfbee;
    }
  }
}

@include for-phone-only {
  .rounded-button {
    .button-content {
      min-width: 120px;
      .button-text {
        padding: 0 10px 0 10px;
      }
      .button-icon {
        height: 30px;
        width: 30px;
        min-width: 30px;
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
  .detail-button {
    margin-top: 20px;
     .button-content {
       .button-text {
         font-size: 18px;
         padding: 0 20px;
       }
       .button-icon {
         height: 40px;
         width: 40px;
         svg {
           height: 40px;
           width: 40px;
         }
       }
     }
  }
}

// light mode class controlled through get parameter
.light-mode {
  .rounded-button {
    background-color: $button-bg-light !important;

    &:not(.static-btn) {
      &:hover {
        background-color: $button-bg-light-hover !important;
      }
    }

    &.selected-btn {
      background-color: $button-icon-bg !important;
    }

    .button-content {
      .button-icon {
        background-color: $button-icon-bg;
      }
    }
  }
  .detail-button {
    background-color: $magenta;
    &:not(.static-btn):hover {
      background-color: $button-dark-hover;
    }
    .button-content {
      .button-icon {
        background-color: $button-dark ;
      }
    }
  }

  .save-button {
    padding-top: 20px;

    button {
      background-color: $magenta;
      color: $white;

      &:hover {
        background-color: $magenta-hover;
      }
    }
  }
}
