@import '_asset/scss/_variables';
@import '_asset/scss/_mixins';
@import '_asset/scss/_fonts';
@import '_asset/scss/_day-detail';
@import '_asset/scss/_footer';
@import '_asset/scss/_buttons';
@import '_asset/scss/_week-detail';
@import '_asset/scss/_image-placeholder';
@import '_asset/scss/_unsubscribe';
@import '_asset/scss/_modal';

html {
  font-family: 'TeleNeo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background: url("../_asset/img/dark-background.jpg")  no-repeat center fixed $body-background;
  background-size: cover;
  color: $white;
}

.page-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrapper,html,body {
  height: 100%;
  line-height: 1.1;
}

.content-wrapper {
  flex-grow: 1;
  margin: 0 auto;
  padding: 15px;

  .header-wrapper {
    display: flex;
    align-items: center;
    color: $white;
    padding: 40px 0 20px;

    .color-mode-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      svg {
        width: 24px;
        height: 24px;
        background: transparent;

        &.dark-mode {
          fill: $gray-text;
        }
        &.light-mode {
          fill: $white;
        }
      }
    }

    &:last-child {
      padding-bottom: 0;
    }

    .header-text {
      font-size: 32px;
      padding-top: 2px;
      text-align: center;
      .topic {
        padding-right: 10px;
        &::after {
          content: ', ';
        }
        &:last-child {
          padding-right: 0;
          &::after {
            content: '';
          }
        }
      }

      &.month {
        font-weight: 600;
        padding-right: 10px;
      }
    }

    .header-month {
      display: flex;
      align-items: center;
      min-width: 300px;

      .empty {
        width: 30px;
        height: 30px;
      }

      svg {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $element-background;
        cursor: pointer;
        margin-left: 0;
        margin-right: auto;
        fill: $white;
        transition: background-color 200ms;
        -webkit-tap-highlight-color: transparent;

        &:hover {
          background-color: $detail-button-bg-hover;
        }

        &:last-child {
          margin-right: 0;
          margin-left: auto;
        }
      }
    }

    .header-divider {
      flex-grow: 1;
      border: none;
      border-top: 1px solid $magenta;
      height: auto;
      margin: auto 20px;
    }
  }

  .table-wrapper {
    width: 100%;
    overflow: auto;
    overflow-y: hidden;

    .control-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .filters {
        button:first-of-type {
          margin: 0 10px;
        }
      }
    }

    table {
      margin: 0 auto;
      table-layout: auto;
      width: 100%;
      height: 100%;

      &.calendar-table {
        border-collapse:separate;
        border-spacing:0 10px;
      }

      tbody {
        overflow: auto;
        width: 100%;

        tr {
          width: 100%;

          &.row-selected {
            background-color: $element-background;
            transition: background-color 200ms;

            td:first-child { border-top-left-radius: 15px; }
            td:last-child { border-top-right-radius: 15px; }
            td:first-child { border-bottom-left-radius: 15px; }
            td:last-child { border-bottom-right-radius: 15px; }

            td .cell-wrapper:not(.selected-cell) {
              &:hover {
                background-color: $detail-button-bg-hover;
              }
            }
          }

          &:not(:last-child) {
              padding-bottom: 10px;
          }

          td {
            overflow: hidden;
            vertical-align: top;

            .cell-wrapper {
              display: flex;
              flex-direction: column;
              height: 100%;
              width: 100%;
              min-width: 150px;
              cursor: pointer;
              font-size: 50px;
              padding: 10px 40px 5px;
              transition: background-color 200ms;
              border-radius: 15px;

              &.selected-cell {
                background-color: $magenta;
              }

              .cell-date {
                font-weight: 900;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
              }

              .cell-day {
                font-size: 14px;
                text-align: center;
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }

    &.top-event {

      table {
        table-layout: fixed;
      }

      tr:not(:last-child) {
        td {
          padding-bottom: 10px;
        }
      }

      td:first-child {
        padding-right: 5px;
      }
      td:last-child {
        padding-left: 5px;
      }

      .cell-wrapper {
        background-color: $element-background;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 200px;
        padding: 20px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        cursor: default;

        .cell-image {
          z-index: -1;
          position: absolute;
          right: -70px;
          top: -70px;
          height: 100%;
          width: 350px;

          img {
            height: auto;
            width: 100%;
          }
        }

        .cell-detail-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-grow:1;

          .cell-date {
            color: $white;
            justify-content: flex-start;
            font-size: 54px;
          }

          .cell-event-time {
            color: $white;
            font-size: 20px;
            display: flex;
            flex-direction: row;
          }

          .cell-content {
            display: flex;
            flex-direction: row;
            .cell-detail {
               flex-grow: 1;

              .cell-zone {
                font-size: 20px;
                color: $white;
              }

              .cell-event {
                display: flex;
                flex-direction: column;
                font-size: 26px;
                font-weight: 800;

                .cell-event-name {
                  flex: 1;
                }
              }
            }
          }
        }

        .cell-button {
          display: flex;
          align-items: center;
          cursor: pointer;

          svg{
            fill: $white;
            height: 80px;
            width: 80px;
          }
        }
      }
    }
  }

  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    color: $white;
    font-size: 30px;
    padding: 40px 0;

    &::before, &::after {
      content: '';
      flex: 1;
      border-bottom: 2px solid $magenta;
    }
    &::before {
      margin-right: .35em;
    }
    &::after {
      margin-left: .35em;
    }
  }
}

.empty-cell {
  background-color: $body-background;
  border-radius: 20px;
  padding: 14px;
  display: flex;
  justify-content: center;
  color: $element-background;
  font-size: 30px;
  font-weight: 700;

  &.day-detail {
    padding: 0;
    background-color: transparent;
    color: $detail-button-bg;

    .holiday {
      text-align: center;
    }
  }
}

.loader-wrapper {
  background-color: $body-background;
  border-radius: 20px;
  padding: 10px;
}

.loader {
  border: 5px solid transparent;
  border-radius: 50%;
  border-top: 5px solid $detail-button-bg;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1.2s linear infinite; /* Safari */
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
  padding: 10px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@include for-phone-only {
  .empty-cell {
    font-size: 24px;
    border-radius: 5px;
    align-items: center;
  }

  .content-wrapper {
    width: 100%;
    padding: 5px;

    .divider {
      &.top-event{
        padding: 30px 0;
      }
    }

    .header-wrapper {
      padding: 15px 5px 20px;
      flex-direction: column-reverse;
      align-items: center;

      .color-mode-icon {
        top: 5px;
        right: 5px;
      }
    }

    .table-wrapper {
      &.top-event {
        tr, td {
          padding: 0 !important;
        }

        .cell-wrapper {
          padding: 10px !important;
          min-height: 180px;

          &:hover {
            background-color: $element-background;
          }

          .cell-detail-wrapper {
            .cell-date{
              font-size: 46px;
            }
            .cell-detail {
              .cell-zone {
                font-size: 18px;
              }
              .cell-event{
                font-size: 22px;
                font-weight: 700;
                .cell-event-time {
                  min-width: 70px;
                  width: 70px
                }
              }
            }
          }
          .cell-button {
            svg {
              height: 60px;
              width: 60px;
            }
          }
          .cell-image {
            width: 300px;
          }
        }
        .detail-cell {
          padding: 10px !important;
        }
      }

      thead {
        display: none;
      }

      tbody, {
        overflow: unset;

        tr {
          display: flex;
          flex-direction: row;

          &.row-selected {
            background-color: unset !important;
            td:first-child, td:last-child {
              border-radius: 5px !important;
            }
          }

          &:not(:last-child){
            padding-bottom: 0 !important;
          }

          td {
            display: block;
            margin: 3px;
            flex: 1;

            .cell-wrapper {
              background-color: $element-background;
              border-radius: 5px !important;
              font-size: 20px !important;
              padding: 3px !important;
              min-width: 40px !important;
              min-height: 40px;
              justify-content: center;

              &:hover {
                background-color: $detail-button-bg-hover;
                border-radius: 5px !important;
              }

              .cell-date {
                font-weight: 700 !important;
              }

              .cell-day {
                padding-bottom: 0 !important;
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }
  }

  // light mode class controlled through get parameter
 .light-mode {
    td {
      background-color: $white !important;
      border-radius: 5px;
      .cell-wrapper:not(.selected-cell) {
        background-color: $white !important;
        &:hover {
          background-color: $cell-hover-bg !important;
        }
      }
    }
  }
}

@include for-tablet-portrait-up {
  .content-wrapper {
    width: 100%;
  }
}

@include for-desktop-up {
  .content-wrapper {
    width: 95%;
  }
}

@include for-big-desktop-up {
  .content-wrapper {
    width: 80%;
  }
}

// TOAST
.Toastify__toast {
  height: auto;
  min-height: 48px;
  border-radius: 10px;
  padding: 12px 15px;
  font-size: 12px;
}

.Toastify__toast--success {
  background-color: $green;
}

.Toastify__toast--error {
  background-color: #ae1010;
}

.Toastify__toast-body {
  .toast {
    display: flex;
    align-items: center;
    svg {
      padding-right: 10px;
      fill: $white;
      height: 20px;
      width: 20px;
    }
  }
}

// light mode class controlled through get parameter
.light-mode {
  background: url("../_asset/img/light-background.png") no-repeat center fixed $white;
  background-size: cover;
  color: $gray-text;

  .page-wrapper .content-wrapper{
    .header-wrapper {
      .header-month {
        svg {
          background-color: $magenta;
          fill: $white;

          &:hover {
            background-color: $button-dark-hover;
          }
        }
      }
      .header-text {
        color: $gray-text;
      }
    }
    .table-wrapper {
      table {
        tbody {
          tr {
            &.row-selected {
              background-color: $light-element-background;

              td .cell-wrapper:not(.selected-cell) {
                &:hover {
                  background-color: $cell-hover-bg;
                }
              }
            }
            td {
              .cell-wrapper {
                .cell-date, .cell-day {
                  color: $magenta ;
                  transition: color 200ms;
                }
                &.selected-cell {
                  .cell-date, .cell-day {
                    color: $white ;
                  }
                }
              }
            }
          }
        }
      }
    }

    .top-event {
      .cell-wrapper {
        background-color: $body-background !important;

        .cell-detail-wrapper {
          .cell-date {
            color: $watermark-magenta;
          }

          .cell-detail {
            .cell-zone {
              color: $watermark-magenta;
            }

            .cell-event {
              .cell-event-time, .cell-event-name {
               color: $white;
              }
            }
          }
        }
      }
    }

    .divider {
      color: $gray-text
    }
  }

  .empty-cell {
    background-color: $white;
    color: $detail-button-bg;

    &.day-detail {
      background-color: transparent;
      color: $detail-button-bg;
    }
  }

  .loader-wrapper {
    background-color: $white;
  }
}
