.week-detail {
  table {
    table-layout: fixed !important;
    tr {
      border-left: 1px solid $magenta;
      border-right: 1px solid $magenta;

      td {
        padding: 10px;
        border-right: 1px solid $magenta;

        &.holiday-cell {
          padding: 0;
        }

        .event {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: $white;
          position: relative;
          height: auto  ;
          cursor: pointer;
          padding-bottom: 10px;
          min-height: 100%;
          border-bottom: 1px solid $element-background;

          &.holiday {
            cursor: default;
            background-color: $element-background;
            height: 100%;
            padding: 50px 0px;

            .event-text {
              padding: 5px;
              font-size: 22px;
              font-weight: 700;
              color: $magenta;
              display: flex;
              align-items: center;
              max-width: 100%;
              width: auto;
            }
          }

          &:not(:first-child) {
            border-top: 1px solid $element-background;
          }

          .event-text {
            text-align: center;
            padding-bottom: 5px;
            font-size: 18px;
            font-weight: 900;
            width: 100%;
          }

          .event-time-zone {
            text-align: center;
            flex-grow: 1;
            .event-time, .event-zone {
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              padding-bottom: 5px;
              padding-right: 5px;

              &:last-child {
                padding-right: 0;
              }
            }
          }

          .online {
            border-radius: 10px;
            height: 20px;
            width: 20px;
            background-color: $button-icon-bg;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 10px;
            left: 0;

            svg {
              height: 16px;
              width: 16px;
              fill: $white;
            }
          }
        }

        &:last-child {
          border-right: none;
        }
        img {
          max-width: 100%;
          max-height: 90px;
          width: auto;
        }
      }
    }
  }
}

// light mode class controlled through get parameter
.light-mode {
  .week-detail {
    table {
      table-layout: fixed !important;
      tr {
        border-left: 1px solid $white;
        border-right: 1px solid $white;

        td {
          border-right: 1px solid $white;

          .event {
            color: $gray-text;
            border-bottom: 1px solid $element-background;

            &.holiday {
              background-color: transparent;
              .event-text {
                color: $magenta;
              }
            }

            &:not(:first-child) {
              border-top: 1px solid $element-background;
            }

            .online {
              background-color: $button-icon-bg;

              svg {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }
}
