.image {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.big {
    width: 50%;
    max-height: 250px;
  }

  img {
    max-width: 150px;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  &.event-detail {
    img {
      max-width: 220px;

      &.picture {
        max-width: 400px;
        padding-left: 20px;
      }
    }
  }

  .empty-image {
    width: 100%;
    height: 100%;
    background-color: $element-background;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    .logo-text {
      font-size: 40px;
      font-weight: 900;
      color: $element-background;
    }
  }
}

@include for-phone-only {
  .image {
    justify-content: flex-start;

    &.event-detail {
      img {
        max-width: 240px;

        &.picture {
          width: 100%;
          padding: 20px;
        }
      }
    }
  }
}
