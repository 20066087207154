.detail-cell {
  padding: 30px !important;
  cursor: default !important;
  border-radius: 20px;
  background-color: $element-background;

  .detail-cell-content {

    .header-wrapper {
      padding-top: 20px !important;

      .header-divider {
        &.last {
          margin: 13.7px 20px !important;
        }
      }

      .button-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        min-width: 50px;
        border-radius: 20px;
        background-color: $detail-button-bg;
        cursor: pointer;
        margin-right: 10px;

        svg {
          height: 40px;
          width: 40px;
        }

        &:hover {
          background-color: $detail-button-bg-hover;
        }
      }

      .header-text {
        color: $white ;
        font-size: 24px;
        font-weight: 700;
        &.day {
          padding-left: 10px;
          letter-spacing: 1px;
        }
        &.time {
          padding-left: 15px;
          padding-right: 10px;
        }
      }

      svg {
        fill: $white;
        height: 26px;
        width: 26px;
      }
    }

    .detail-cell-wrapper {
      display: flex;
      flex-direction: row;

      &:not(:last-child) {
        margin-bottom: 20px
      }

      .event-image {
        display: flex;
        flex-direction: column;
        width: 20%;
        align-items: center;

        img {
          height: auto;
          width: auto;
          max-width: 200px;
        }

        .event-image-text {
          color: $magenta;
          text-align: center;
          font-weight: 700;
          font-size: 16px;
        }
      }

      .event-information {
        color: $white;
        padding: 0 30px;
        width: 100%;
        flex: 1 1 100%;

        .name {
          font-size: 26px;
          font-weight: 800;
        }

        .organization {
          font-size: 20px;
        }

        .detail {
          display: flex;
          flex-direction: row;
          padding-top: 20px;

          .time {
            color: $white;
            font-size: 26px;
            font-weight: 800;
            padding-top: 8px;
            padding-right: 20px;
            height: 40px;
            min-width: 162px;
          }

          .button {
            padding-right: 10px;
            &:last-child {
              padding-right: 0;
            }
          }
        }
        .more-detail-button {
          padding-top: 20px;
        }
      }
    }

    .event-detail-cell-wrapper {
      .event-header {
        display: flex;
        flex-direction: row;
        color: $white;
        width: 100%;
        max-width: 100%;
        padding: 20px 0 ;

        .name-org {
          flex-direction: column;
          width: 60%;
          flex: 1;
          padding-right: 30px;

          .name {
            font-size: 30px;
            font-weight: 900;
          }

          .organization {
            font-size: 24px;
          }

        }
        .additional-info {
          width: 40%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;

          .button-group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .button {
              padding-right: 10px;
              padding-top: 10px;
              &:last-child {
                padding-right: 0;
              }
            }
          }
          .zone {
            color: $magenta;
            font-size: 16px;
            font-weight: 600;
            padding-left: 10px;
            height: 40px;
            display: flex;
            align-items: center;
          }
        }
      }

      .detail {
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;

        .description {
          flex: 1;
          width: 60%;
          font-size: 20px;

          a {
            text-decoration: underline;
          }

          p {
            display: block;
            margin-top: 1em;
            margin-bottom: 1em;
            margin-left: 0;
            margin-right: 0;
          }

          ul {
            display: block;
            list-style-type: disc;
            margin-top: 1em;
            margin-bottom: 1em;
            margin-left: 0;
            margin-right: 0;
            padding-left: 40px;
          }
        }
      }

      .divider {
        padding: 40px 0 10px;

        .button-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          width: 50px;
          min-width: 50px;
          border-radius: 20px;
          background-color: $magenta;

          svg {
            height: 40px;
            width: 40px;
            fill: $white;
          }
        }
      }
      .divider-text {
        text-transform: uppercase;
        color: $magenta;
        text-align: center;
        font-size: 30px;

        .already-signed {
          padding-top: 30px;
          text-transform: lowercase;
        }
      }

      .event-form {
        margin: 30px 0;

        form {
          width: 100%;
          display: flex;
          flex-direction: row;

          .input-with-icon {
            position: relative;
            width: 100%;
            margin-right: 30px;
            display: flex;
            align-items: center;


            .input-icon {
              position: absolute;
              display: flex;
              padding-left: 15px;
              top: 25%;
            }

            svg {
              fill: $gray;
              height: 30px;
              width: 30px;
            }
          }

          input {
            padding: 10px 10px 10px 60px;
            border: none;
            outline: none;
            border-radius: 20px;
            height: 60px;
            box-sizing: border-box;
            font-size: 20px;
            width: 100%;
            transition: border 200ms;
            color: $gray-text;

            &.error-input {
              border: 2px solid #e20574;
            }

            &::-webkit-input-placeholder,  &:-ms-input-placeholder, &::placeholder { /* Edge */
              color: $gray;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          button[type=submit] {
            border: none;
            outline: none;
            border-radius: 20px;
            height: 60px;
            box-sizing: border-box;
            font-size: 20px;
            width: 250px;
            min-width: 250px;
            background-color: $magenta;
            color: $white;
            padding: 10px;
            cursor: pointer;
            transition: background-color 200ms;
            position: relative;

            &.loading {
              background-color: $magenta-hover;
            }
            .loader-wrapper {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: transparent;
              .loader {
                border-top-color: $magenta;
              }
            }


            &:hover {
              background-color: $magenta-hover;
            }
          }
        }
        .error-msg {
          position: absolute;
          top: 70px;
          font-size: 16px;
          color: white;
        }
      }

      .share-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 30px 0;

        .share-button {
          width: 100%;
          background-color: $detail-button-bg;
          margin-right: 30px;
          border-radius: 20px;
          transition: background-color 200ms;
          min-height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: 20px;
          cursor: pointer;
          position: relative;
          -webkit-tap-highlight-color: transparent;

          &.loading {
            background-color: $detail-button-bg-hover;
          }

          .loader {
            position: absolute;
            border: 10px solid transparent;
            border-top: 10px solid $detail-button-bg;
            width: 60px;
            height: 60px;
          }

          .share-text {
            text-align: center;
          }

          svg {
            fill: $white;
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            background-color: $detail-button-bg-hover;
          }
        }
      }
    }
  }
}

// FIX for phones
@include for-phone-only {
  .detail-cell {
    padding: 10px !important;
    border-radius: 5px !important;
    height: 100% !important;
    width: 100%;

    .detail-cell-content {
      .header-wrapper {
        padding: 0 !important;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .button-icon {
          height: 40px;
          width: 40px;
          min-width: 40px;
          border-radius: 10px;
          svg {
            height: 30px;
            width: 30px;
          }
        }

        .header-day {
          padding-right: 10px;
          .header-text {
            padding-left: 0;
            &.day {
              padding-left: 10px;
            }
          }
        }
        .header-text {
          font-size: 22px;
          padding-left: 10px;
        }
        .header-divider {
          margin: 20px 0;
          &.last {
            margin: 15px 0 5px 0 !important;
          }
        }

        .mobile-header-event-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          width: auto;
          height: 50px;
          flex-grow: 1;

          .header-day .header-text{
            font-size: 22px ;
          }
          .header-time {
            .header-text {
              font-size: 20px;
              &.time {
                padding: 0;
              }
            }
          }
        }
      }

      .detail-cell-wrapper {
        .event-image {
          width: 30%;
          margin-right: 10px;

          .time {
            text-align: center;
            font-size: 24px;
            font-weight: 700;
          }
        }
        .event-information {
          padding: 0;
          .name {
            padding: 10px 0 5px;
            font-size: 24px;
          }

          .organization-time {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .organization {
              font-size: 20px;
            }
            .time {
              font-size: 20px;
              font-weight: 700;
              color: $white;
            }
          }

          .more-detail-button {
            padding-top: 15px;
            .buttons-logo {
              display: flex;
              .detail{
                padding-top: 0;
                flex-wrap: wrap;
                width: 50%;
                flex-direction: column;

                .button {
                  padding: 0 10px 10px;
                }
              }
              .image {
                padding-bottom: 10px;
              }
            }
            .detail-button {
              margin-top: 10px;
            }
          }
        }
      }

      .event-detail-cell-wrapper {
        .event-header {
          flex-direction: column;
          padding: 10px 0 !important;
          .name-org {
            width: 100%;
            padding-right: 0;
            .name{
              font-size: 28px;
              font-weight: 700;
            }
            .organization {
              font-size: 26px;
            }
          }
          .additional-info {
            width: 100%;
            padding: 0;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            .zone {
              padding: 0;
            }
          }
        }
        .detail {
          flex-direction: column;
          padding-bottom: 0;
          .description {
            width: 100%;
            padding: 0 0 20px;
          }
          .image {
            width: 100%;
            justify-content: center;
          }
        }
        .divider {
          padding: 10px 0;
          .button-icon {
            width: 45px;
            min-width: 45px;
            height: 45px;
            border-radius: 15px;
            svg {
              height: 30px;
              width: 30px;
            }
          }
        }
        .divider-text {
          font-size: 24px;
        }
        .event-form {
          margin: 15px 0;
          form {
            flex-direction: column;
            .input-with-icon {
              margin-right: 0;
              padding-bottom: 10px;

              .input-icon {
                svg {
                  height: 25px;
                  width: 25px;
                }
              }

              &.error-input {
                margin-bottom: 30px;
              }

              input {
                font-size: 16px !important;
                padding: 8px 8px 8px 52px;
                height: 50px !important;
              }
            }
            button {
              max-width: 100% !important;
              font-size: 18px !important;
            }
            button[type=submit]{
              height: 50px;
              width: 100% !important;
            }
          }
        }
        .share-wrapper {
          flex-direction: column;
          margin: 15px 0;

          .share-button {
            min-height: 90px;

            .share-text {
              font-size: 18px;
            }

            svg {
              margin-bottom: 5px;
            }
            &:not(:last-child) {
              margin: 0 0 10px;
            }
          }
        }
      }
    }
  }

    .detail-cell-description {
      flex-direction: column !important;

      .detail-cell-header, .detail-cell-privacy {
        width: 100% !important;
        padding: 0 !important;
      }
    }

    .detail-cell-actions {
      flex-direction: column !important;
      .detail-cell-text, .detail-cell-logo-button {
        width: 100% !important;
        padding: 0 !important;
      }
      .detail-cell-logo-button {
        padding-top: 20px !important;
        .detail-cell-button {
          height: auto !important;
        }
      }
    }
}

// light mode class controlled through get parameter
.light-mode {
  .detail-cell {
    background-color: $light-element-background;

    .detail-cell-content {
      .header-wrapper {
        .button-icon {
          background-color: $magenta;
          &:hover {
            background-color: $button-dark-hover;
          }
        }
        svg.time {
          fill: $gray-text
        }
      }
      .detail-cell-wrapper {
        .event-information {
          .name, .organization {
            color: $magenta;
          }
          .time {
            color: $gray-text;
          }
          .detail {
            .time {
              color: $gray-text;
            }
          }
        }
      }
      .event-detail-cell-wrapper {
        .event-header {
          .name-org {
            color: $magenta
          }
        }
      }
      .event-form {
        form {
          .input-with-icon {
            input {
              border: 1px solid $gray-light !important;

              &.error-input {
                border: 1px solid $magenta !important;
              }
            }
          }

          .error-msg {
            color: $magenta !important;
          }
        }
      }
      .share-wrapper {
        .share-button {
          background-color: $magenta !important;
          &:hover {
            background-color: $button-dark-hover !important;
          }
          .share-text {
            color: $white;
          }
        }
      }
    }
  }
}
