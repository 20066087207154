.footer {
  margin-top: 40px;
  min-height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: $white;

  .footer-logo {
    height: 100px;
    width: auto;
  }

  .footer-launch {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;

    .footer-icons {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transform: scale(0.7);
        -webkit-transform:scale(0.7);

        #svg_96, #svg_97, #svg_98 {
          fill: $white !important;
        }
      }
    }
  }
}

@include for-phone-only {
  .footer {
    flex-direction: column;

    .footer-launch {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        padding-top: 10px !important;
      }
    }
  }
}

// light mode class controlled through get parameter
.light-mode {
  .footer {
    color: $gray-text;
    .footer-launch {
      .footer-icons {
        svg {
          #svg_96, #svg_97, #svg_98 {
            fill: $gray-text !important;
          }
        }
      }
    }
  }
}
