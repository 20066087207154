// colors
$body-background: #35011d;
$element-background: #E205744D;

$button-background: #0FD4B399;
$button-bg-hover: #0FD4B375;
$button-icon-bg: #0FD4B3;

$button-bg-light: #0CAC91;
$button-bg-light-hover: #0CAC91CC;

$button-icon-bg-selected: #0FD4B335;
$button-background-selected: #0FD4B335;

$detail-button-bg: #E2057499;
$detail-button-bg-hover: #E2057477;


$gray-dark: #5a5a5a;
$gray: #999999;
$gray-light: #d9d8d8;

$magenta: #e20574;
$magenta-hover: darken($magenta, 5%);
$watermark-magenta: #f456a5;

$green: #10ae3f;
$white: #fbfbfb;


// light mod
$cell-hover-bg: #E2057438;
$button-hover: #E20574BB;
$detail-button-bg-hover: #E2057444;
$button-dark: #BF0360;
$button-dark-hover: $magenta-hover;
$light-element-background: #FFFFFFB3;
$gray-text: #3E3E3E;


// breakpoints

$phone-only: 599px;
$tablet-portrait-up: 600px;
$tablet-landscape-up: 900px;
$desktop-up: 1200px;
$big-desktop-up: 1800px;

