.overlay {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #44444499;
  z-index: 10;
  overflow-y: hidden;
  transition: background-color 2000ms;
  overscroll-behavior-y: none;

  .modal {
    width: 550px;
    min-height: 450px;
    background-color: $magenta-hover;
    position: relative;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 2000ms;
    overflow: auto;
    padding: 10px;

    img {
      width: auto;
      height: auto;
      max-width: 200px;
      border: 2px solid black;
      background: white;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      background-color: $magenta-hover;
      transition: background-color 200ms;

      &:hover {
        background-color: $magenta;
      }

      svg {
        fill: $white;
        height: 30px;
        width: 30px;
      }
    }

    .name {
      padding-top: 20px;
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      max-width: 100%;
      width: 100%;
    }

    .from {
      padding-top: 5px;
      font-size: 18px;
      span:not(:last-child) {
        padding-right: 5px;
      }
      .day {
        font-weight: 600;
      }
    }
  }
}

@include for-phone-only {
  .overlay {
    .modal {
      width: 90%;
      min-height: 50%;
      height: auto;
      .name {
        text-align: center;
      }
    }
  }
}

// light mode class controlled through get parameter
.light-mode {
  .overlay {
    .modal {
      background-color: $white;

      .close-button {
        background-color: $white;
        &:hover {
          background-color: $cell-hover-bg;
        }
        svg {
          fill: black;
        }
      }
    }
  }
}
