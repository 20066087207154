.unsubscribe-wrapper {
  height: 100vh;
  min-height: 500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .unsubscribe-card {
    background-color: $element-background;
    margin: 0 auto;
    width: 50%;
    min-height: 300px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .unsubscribe-text {
      width: 50%;
      font-weight: 700;
      font-size: 28px;
      color: $white;
      text-align: center;
    }
  }
}

@include for-phone-only {
  .unsubscribe-wrapper {
    .unsubscribe-card {
      width: 90%;
      .unsubscribe-text {
        width: 80%;
      }
    }
  }
}

@include for-tablet-portrait-up {
  .unsubscribe-wrapper {
    .unsubscribe-card {
      width: 80%;
      .unsubscribe-text {
        width: 70%;
      }
    }
  }
}

@include for-desktop-up {
  .unsubscribe-wrapper {
    .unsubscribe-card {
      width: 50%;
      .unsubscribe-text {
        width: 50%;
      }
    }
  }
}

@include for-big-desktop-up {
  .unsubscribe-wrapper {
    .unsubscribe-card {
      width: 40%;
      .unsubscribe-text {
        width: 40%;
      }
    }
  }
}

// light mode class controlled through get parameter
.light-mode {
  .unsubscribe-wrapper {
    .unsubscribe-card {
      background-color: $white;

      .unsubscribe-text {
        color: $magenta;
      }
    }
  }
}
